<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.sendUser"
            size="medium"
            placeholder="发送者昵称，姓名"
          />
        </nav>
        <nav class="input">
          <el-input
            v-model="form_2.receiveUser"
            size="medium"
            placeholder="接受者昵称，姓名"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.status" placeholder="类型" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="未读" :value="0" />
            <el-option label="已读" :value="1" />
            <!-- <el-option label="已交换名片" :value="2" />
            <el-option label="不合作" :value="3" />
            <el-option label="无名片" :value="4" /> -->
          </el-select>
        </nav>
        <nav class="input" style="width: auto;">
          <el-select
            v-model="timeState"
            @change="inTheAir"
            style="width: 150px;margin-right: 5px;"
            placeholder="状态"
            size="medium"
          >
            <el-option label="申请时间" :value="1" />
            <el-option label="查看时间" :value="2" />
            <!-- <el-option label="交换时间" :value="3" /> -->
          </el-select>
          <!-- <div class="box" style="width:300px"> -->
          <el-date-picker
            v-if="timeState == 1"
            v-model="form_2.createDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:300px"
            size="medium"
          />
          <el-date-picker
            v-if="timeState == 2"
            v-model="form_2.viewDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:300px"
            size="medium"
          />
          <el-date-picker
            v-if="timeState == 3"
            v-model="form_2.exchangeDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width:300px"
            size="medium"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="userId" label="用户ID" />
        <el-table-column prop="sendUser" label="申请人" />
        <el-table-column prop="receiveUser" label="合作对象" />
        <el-table-column
          prop="title"
          width="300"
          label="合作内容"
          :show-overflow-tooltip="true"
        >
          <!-- <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.title"
              placement="top"
            >
              <span>{{ row.title }}</span>
            </el-tooltip>
          </template> -->
        </el-table-column>
        <el-table-column prop="content" label="附言">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.content"
              placement="top"
            >
              <span>{{ row.content }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="endTimeType" label="合作截至时间">
          <template slot-scope="{ row }">
            <p>{{ row.endTimeType ? '长期有效' : row.endTime }}</p>
          </template>
        </el-table-column> -->
        <el-table-column prop="terminal" label="来自终端" />

        <el-table-column prop="status" label="状态">
          <template slot-scope="{ row }">
            <el-tag
              v-show="show_status(row.status)"
              type="warning"
              size="small"
              class="box"
              >{{ show_status(row.status) }}</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column prop="applyTime" label="申请时间" />
        <el-table-column prop="viewTime" label="查看时间" />
        <!-- <el-table-column prop="exchangeTime" label="交换时间" /> -->
      </el-table>
      <el-pagination
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
let that
// import $ from 'jquery'
import rules from '../../utils/rules'
import show from '../../components/countDowns/show.vue'
// import mixins from '../../mixin/getdiction'
import remark from '../../components/remark/index'
export default {
  components: {
    Show: show,
    Remark: remark
  },
  data() {
    return {
      title: '',
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      confing: '',
      btn: true,
      action: {
        action: 'enterprise'
      },
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      proof: false,
      timeState: 1,
      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_2: {
        page: 1,
        pageSize: 10,
        sendUser: '',
        receiveUser: '',
        status: '',
        createDate: [],
        viewDate: [],
        exchangeDate: []
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      s_form: {
        page: 1,
        pageSize: 10,
        sendUser: '',
        receiveUser: '',
        status: '',
        createDate: [],
        viewDate: [],
        exchangeDate: []
        // order: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      form_show: {},
      area: []
    }
  },
  computed: {
    show_status() {
      return val => {
        var key
        switch (val) {
          case 0:
            key = '未读'
            break
          case 1:
            key = '已读'
            break
        //   case 2:
        //     key = '已交换名片'
        //     break
        //   case 3:
        //     key = '不合作'
        //     break
        //     case 4:
        //     key = '无名片'
        }
        return key
      }
    }
  },
  // mixins: [mixins],
  beforeCreate() {
    that = this
  },
  updated() {
    //   console.log(this.form);
  },

  created() {
    // this.getconfig()
    // this.getconfig('user')
    this.getlist()
    sessionStorage.setItem('action', 'cooperation')
  },
  mounted() {
    console.log(rules.req('撒打算'))
    // console.log(this.confing_d)
  },
  methods: {
    reset() {
      this.form_2 = this.$options.data.call(this).form_2
      this.s_form = { ...this.form_2 }
      this.getlist()
    },
    subimt() {
      this.currentPage = 1
      this.form_2.page = 1
      this.s_form = { ...this.form_2 }
      this.getlist()
    },
    show_type(val) {
      if (val == 1) {
        return '提供服务'
      } else {
        return '需要资源'
      }
    },
    check() {
      console.log(1)
    },
    information(row) {
      this.showinfo = true
      // this.form_show = row
      this.$http
        .get('/admin/UserSpeaker/getById', { params: { id: row.id } })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_show = res.data
          }
        })
    },
    datum(row) {
      location.href = this.baseurl + this.yu + row.document
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else if (c.prop == 'views') {
          s = 'report_views'
        } else {
          s = 'report_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },

    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      // this.getlist()

      this.s_form.page = e

      this.getlist(this.s_form)
    },
    getlist(val) {
      var form = val || this.form_2
      this.$http
        .get('/admin/Cooperation/getApplyList', {
          params: {
            ...form,
            createDate: form.createDate.join('~'),
            viewDate: form.viewDate.join('~'),
            exchangeDate: form.exchangeDate.join('~'),
            status: form.status == -1 ? '' : form.status
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res)
            this.list = res.data.list
            // console.log(this.list)
            this.total = res.data.totalCount
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    async beforedown() {
      const phone = this.$cookies.get('phone')
      if (!phone) {
        this.$message.error('没有绑定手机号')
        return
      }
      if (!this.proof) {
        this.dioA = true
      } else {
        this.download()
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {
        //       ...this.form_3
        //     }
        //   }
        // )
        // this.btn = true
        // // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          const { data: res } = await this.$http.get(
            '/admin/UserSpeaker/exportExcel',
            {
              params: {
                ...this.form_2,
                ...this.form_3
              }
            }
          )
          this.proof = true
          this.btn = true
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.href = res.data.url
            this.dioA = false
          }
        }
      }
    },
    add() {},
    inTheAir() {
      console.log(113213)
      this.form_2.createDate = []
      this.form_2.viewDate = []
      this.form_2.exchangeDate = []
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },

    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // sessionStorage.setItem('flag', false)
      // this.p_a = true
      this.$router.push({
        name: 'cooperation_list_add',
        params: {
          id
        }
      })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/cooperation/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    setTop(row, val) {
      // console.log(1)
      // console.log(row, id)
      this.$http
        .post('/admin/cooperation/top', {
          id: row.id,
          top: val
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              customClass: 'zZindex'
            })
          }
          // this.btn1 = true
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/Cooperation/hide', { id: id, isHide: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },
    chagne(row) {
      this.$http
        .post('/admin/UserSpeaker/editService', {
          id: row.id,
          waId: row.waId.join(',')
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.getlist()
          } else {
            this.getlist()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.box {
  display: inline-block;
  margin: 5px;
}
</style>
